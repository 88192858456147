import "./CalendlyButton.scss";

import React from "react";
import { PopupButton } from "react-calendly";
import TagManager from "react-gtm-module";

interface CalendlyProps {
  sleek?: boolean;
  text?: string;
}

export enum CalendlyEvents {
  "profile_page_viewed",
  "event_type_viewed",
  "date_and_time_selected",
  "event_scheduled"
}

function isCalendlyEvent(e: any) {
  return (
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.") === 0
  );
}

window.addEventListener("message", function (e) {
  if (isCalendlyEvent(e)) {
    /* Example to get the name of the event */
    // console.log(
    //   "Event name: ",
    //   e.data.event,
    //   e.data.event.substring(9),
    //   " Event details: ",
    //   e.data.payload
    // );
    TagManager.dataLayer({
      dataLayer: {
        event: e.data.event.substring(9) // remove "calendly." from event name
      }
    });
  }
});

const CalendlyButton: React.FC<CalendlyProps> = ({
  sleek = true,
  text = "Book A Meeting 📅"
}) => {
  const url = "https://calendly.com/bam-digital";

  return (
    <div className="calendly" id="calendly">
      <PopupButton
        url={url}
        styles={{
          height: "800px"
        }}
        pageSettings={{
          backgroundColor: "#282a36",
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: "#00ffd9",
          textColor: "ffffff"
        }}
        rootElement={
          document.getElementById("firstborn") ||
          ((<div />) as unknown as HTMLElement)
        }
        className={`calendly-button ${sleek ? "calendly-sleek" : ""}`}
        text={text}
        utm={{
          utmSource: "BAM Digital website"
        }}
      />
    </div>
  );
};

export default CalendlyButton;
