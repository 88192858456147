import React from "react";
import TagManager from "react-gtm-module";
import { Route, Routes } from "react-router-dom";

import { CalendlyEvents } from "./Obelisk/Components/CalendlyButton";

const Home = React.lazy(() => import("./Pages/Home"));
const Alana = React.lazy(() => import("./Pages/Profiles/Alana"));
const Team = React.lazy(() => import("./Pages/Team"));
const RedirectToRoot = React.lazy(() => import("./Pages/404"));
const Profile = React.lazy(() => import("./Pages/Profile"));
const SiteContainer = React.lazy(
  () => import("./Obelisk/Structure/SiteContainer")
);

TagManager.initialize({
  gtmId: "GTM-MQL3CMD",
  events: {
    profile_page_viewed: CalendlyEvents.profile_page_viewed,
    event_type_viewed: CalendlyEvents.event_type_viewed,
    date_and_time_selected: CalendlyEvents.date_and_time_selected,
    event_scheduled: CalendlyEvents.event_scheduled
  }
});

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SiteContainer />}>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/team">
          <Route index element={<Team />} />
          <Route path="/team/alana" element={<Alana />} />
          {/* <Route
            path=":name"
            element={<Profile />}
            loader={({ params }) => {
              console.log(params);
              return params;
            }}
          /> */}
        </Route>
      </Route>
      <Route path="/admin" element={<RedirectToRoot />}></Route>
      <Route path="*" element={<RedirectToRoot />} />
    </Routes>
  );
};

export default App;
