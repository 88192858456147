import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MainState {
  ref?: any;
}

const initialState: MainState = {
  ref: null
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setScrollRef: (state, action: PayloadAction<any>) => {
      state.ref = JSON.parse(JSON.stringify(action.payload));
    }
  }
});

export const { setScrollRef } = mainSlice.actions;

export default mainSlice.reducer;
